.table_head_cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
}

.table_head_cell:not(:last-child) {
    padding-right: 12px;
}

.table_head_cell:is(:first-child) {
    padding-left: 12px;
}

.table_head_cell_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.table_head_cell_label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table_head_resizer {
    width: 2px;
    height: 16px;
    background-color: rgba(0, 0, 0, 0.2);
}

.table_head_resizer:hover {
    cursor: col-resize;
    background-color: rgba(0, 0, 0, 0.7);
}

.table_head_resizer:active {
    background-color: rgba(0, 0, 0, 0.7);
}

.header-operation-button:hover {
    opacity: 0.2;
}

.header-operation-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 4px;
    opacity: 0;
}

.table_head_cell:hover>.table_head_cell_container>.header-operation-wrapper>.header-operation-container {
    opacity: 1;
}

.table_head_cell:active>.table_head_cell_container>.header-operation-wrapper>.header-operation-container {
    opacity: 1;
}

.header-operation-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.sorting-icon {
    background: url('../../../../public/filter_dsc.svg');
    height: 20px;
    width: 18px;
    margin-left: 5px;
    transition: transform 0.3s ease;
    /* Add transition for smooth rotation */
}

.sorting-asc-icon {
    transform: rotate(180deg);
}

.table_head_cell_label_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.table_head_cell_label_container:hover {
    cursor: pointer;
}