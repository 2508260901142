.recordings-table {
    width: 100%;
    border-spacing: 4px;
}

.recordings-table-thead {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #707070;

}

.recordings-table-tbody {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #707070;
}

.recordings-table-header-cell {
    padding: 0 4px;
}

.recordings-table-cell {
    padding: 0 4px;
}

.recording-info-tooltip {
    position: relative;
    /* If you want dots under the hoverable text */
}

/* Tooltip text */
.recording-info-tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: rgb(112, 112, 112);
    color: rgb(255, 255, 255);
    border-radius: 6px;
    padding: 6px 12px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 105%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.recording-info-tooltip:hover .tooltiptext {
    visibility: visible;
}

.labels-container-wrapper {
    display: flex;
    flex-direction: row;
    height: 32px;
    align-items: center;
}

.labels-container {
    width: 95%;
    flex-wrap: wrap;
    display: flex;
    gap: 10px;
    overflow-y: scroll;
    max-height: 100px;
}

.label-wrapper {
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.delete-label-button {
    margin-left: 4px;
    height: 24px;
    width: 24px;
}

.label-button-text {
    user-select: none;
    font-weight: 600;
    font-size: 14;
    margin: 0;
}

.label-button-text:hover {
    cursor: pointer;
}